import mediterraneanDiet from "./images/mediterraneanDiet.png";
import herbalShampoo from "./images/herbalShampoo.jpg";
import dogIllustration from "./images/dogIllustration.png";
import {
  ItemWrapper,
  Image,
  ImageGallery,
  Text,
  Title,
  Description,
} from "./styled";

const data = [
  {
    image: herbalShampoo,
    title: "Herbal Shampoo",
    description:
      "Discover the power of nature with our all-natural shampoo, crafted from herbs like cedar and Gleditsia sinensis. Infused with the wisdom of traditional Chinese medicine, this gentle formula deeply cleanses and nourishes your hair without harsh chemicals. Rejuvenate your scalp and restore your hair’s natural shine, all while embracing the beauty of nature's remedies. Perfect for all hair types, our shampoo is a sustainable choice for a healthier you and a cleaner planet.",
  },
  {
    image: mediterraneanDiet,
    title: "Calorie Deficit Weight Loss",
    description:
      "Transform your body and health with the simple, sustainable approach of calorie deficit weight loss! By mindfully consuming fewer calories than your body burns, you can achieve your weight loss goals without extreme diets or restrictions. I succeeded with this method and discovered a healthier, more balanced lifestyle that’s easy to maintain. Join me in embracing this empowering, effective approach and start your journey to a healthier, happier you today!",
  },
  {
    image: dogIllustration,
    title: "Dog Illustrator",
    description:
      "Capture the unique personality of your furry friend with custom AI-enhanced digital pet portraits! I combine advanced technology with artistic creativity to create one-of-a-kind illustrations that perfectly reflect your pet's charm. Whether you're looking for a keepsake or a thoughtful gift, my pet portraits bring your beloved companions to life in vibrant detail.",
  }
];

export const Item = () => {
  return (
    <div>
      {data.map((datum) => {
        return (
          <ItemWrapper key={datum.title}>
            <ImageGallery>
              <Image src={datum.image} alt={datum.title} />
            </ImageGallery>
            <Text>
              <Title>{datum.title}</Title>
              <Description>{datum.description}</Description>
            </Text>
          </ItemWrapper>
        );
      })}
    </div>
  );
};
