import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 40px 20px;
  background-color: #f9f9f9;

  h2 {
    margin-bottom: 30px;
  }

  .work-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .project-item {
    width: 300px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
  }

  .project-item:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    border-radius: 8px;
  }

  h3 {
    margin: 15px 0 10px;
  }

  p {
    font-size: 0.9rem;
    color: #555;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
`;

export const ImageGallery = styled.div`
  margin-right: 20px;
`;

export const Image = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
`;

export const Text = styled.div`
  flex: 1;
`;

export const Title = styled.p`
  text-align: start;
font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
`;

export const Description = styled.p`
text-align: start;
  font-size: 1rem;
  color: #555;
`;