import { SESSION_IDS } from "../constants/constants";
import { Wrapper } from "./styled";
import {Item} from "./Item";

export const FunThingsSession = () => {
  return (
    <Wrapper id={SESSION_IDS.funThings}>
      <h2>Fun Things</h2>
      <Item />
    </Wrapper>
  );
};
