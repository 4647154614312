import styled from "styled-components";

export const ProfileContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

export const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 20px;
`;

export const ProfileText = styled.div`
  text-align: left;
`;

export const ProfileHeading = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

export const ProfileDescription = styled.p`
  font-size: 1rem;
  color: #555;
`;