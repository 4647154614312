import React from "react";
import { Map as GoogleMap, APIProvider } from "@vis.gl/react-google-maps";
import { VISITED_PLACES } from "./constants";
import { Marker } from "./marker";

const center = { lat: 49.2827, lng: -123.1207 };
export const Map = () => {
  return (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string}
      onLoad={() => console.log("Maps API has loaded.")}
    >
      <GoogleMap
        style={{ width: "100vw", height: "400px" }}
        defaultCenter={center}
        defaultZoom={3}
        gestureHandling={"greedy"}
        disableDefaultUI={true}
        mapId={"journey-map"}
      >
        {VISITED_PLACES.map((location) => (
          <Marker {...location} />
        ))}
      </GoogleMap>
    </APIProvider>
  );
};
