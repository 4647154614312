import { FULL_NAME } from "../constants/constants";
import { Footer } from "./styled";

export const FooterComponent = () => {
    return <Footer>
    <p>&copy; 2024 {FULL_NAME}. All rights reserved.</p>
    <p>
      Follow me on <a href="https://www.linkedin.com/in/yujie-zeng-1128015a/">LinkedIn</a> | <a href="https://github.com/ArthaZeng?tab=repositories">Github</a>
    </p>
  </Footer>
};