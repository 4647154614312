import styled from 'styled-components';

// Styled components for different sections
export const Container = styled.div`
  font-family: Arial, sans-serif;
  text-align: center;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f4f4f4;

  h1 {
    margin: 0;
  }

  nav ul {
    list-style: none;
    display: flex;
    gap: 15px;
  }

  nav a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
  }
`;

export const Footer = styled.footer`
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;

  a {
    margin-left: 15px;
    color: #333;
    text-decoration: none;
    font-size: 1.2rem;
  }
`;