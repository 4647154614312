export const VISITED_PLACES = [
  // North America
  { name: "Yellowknife", position: { lat: 62.4540, lng: -114.3718 } },
  { name: "Osaka", position: { lat: 34.6937, lng: 135.5023 } },
  { name: "Tokyo", position: { lat: 35.6895, lng: 139.6917 } },
  { name: "North Carolina", position: { lat: 35.7596, lng: -79.0193 } },
  { name: "Washington, D.C.", position: { lat: 38.9072, lng: -77.0369 } },
  { name: "San Francisco", position: { lat: 37.7749, lng: -122.4194 } },
  { name: "Seattle", position: { lat: 47.6062, lng: -122.3321 } },
  { name: "Portland", position: { lat: 45.5152, lng: -122.6784 } },
  { name: "Yellowstone National Park", position: { lat: 44.4280, lng: -110.5885 } },
  { name: "Tofino", position: { lat: 49.1520, lng: -125.9031 } },
  { name: "Montreal", position: { lat: 45.5017, lng: -73.5673 } },
  // Europe
  { name: "Edinburgh", position: { lat: 55.9533, lng: -3.1883 } },
  // Asia
  { name: "Suzhou", position: { lat: 31.2989, lng: 120.5853 } },
];
