import { useCallback, useState } from "react";
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from "@vis.gl/react-google-maps";

type Poi = {lat: number, lng: number};

export const Marker = ({name, position}: { name: string, position: Poi}) => {
  // `markerRef` and `marker` are needed to establish the connection between
  // the marker and infowindow
  const [markerRef, marker] = useAdvancedMarkerRef();

  const [infoWindowShown, setInfoWindowShown] = useState(false);

  // clicking the marker will toggle the infowindow
  const handleMarkerClick = useCallback(() => setInfoWindowShown(isShown => !isShown), []);

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <>
      <AdvancedMarker
        key={name}
        ref={markerRef}
        position={position}
        onMouseEnter={handleMarkerClick}
        onMouseLeave={handleClose}
      />

      {infoWindowShown && (
        <InfoWindow anchor={marker} headerDisabled>
          <div>{name}</div>
        </InfoWindow>
      )}
    </>
  );
};